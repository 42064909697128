import { DefaultTemplate } from "components/templates/DefaultTemplate";
import ExternalAuthTemplate from "components/templates/ExternalAuthTemplate";
import { StoreIdParam } from "config/menu-tool";
import React from "react";
import { useParams } from "react-router";

const ExteranalAuth: React.FC = () => {
  const { storeId } = useParams<StoreIdParam>();
  const storeid = Number(storeId);

  return (
    <DefaultTemplate title="アカウント連係">
      <ExternalAuthTemplate storeId={storeid} />
    </DefaultTemplate>
  );
};

export default ExteranalAuth;
