import React, { useEffect, useState } from "react";

import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";

import { DrinkHeading } from "components/organisms/DrinkHeading";
import { Drink } from "domain/model/Menu";
import Image from "../../domain/model/Image";
import { imageService } from "../../domain/service";

type Props = {
  storeId: string | undefined;
  drinks: Drink[] | null;
};

const DrinkListTemplate: React.FC<Props> = ({ storeId, drinks }) => {
  const storeid = Number(storeId);

  const [s3_images, setS3Images] = useState<Image[]>([]);
  const menuType = "drink";
  const fetchImagePath = () => {
    imageService.fetchAllImage(String(storeId), menuType).then((res) => {
      setS3Images(res);
    });
  };

  useEffect(() => {
    fetchImagePath();
  }, [storeId]);

  return (
    <>
      {drinks?.map((drink: any, index: number) => (
        <>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemText primary={drink.category} />
            </AccordionSummary>
            <AccordionDetails>
              <DrinkHeading
                index={index}
                items={drink}
                drinks={drinks}
                category={drink.category}
                storeId={storeid}
                s3Images={s3_images}
                onImageUploaded={fetchImagePath}
              />
            </AccordionDetails>
          </Accordion>
        </>
      ))}
    </>
  );
};

export default DrinkListTemplate;
