import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

import { SecondaryButton } from "../atoms/buttons/SecondaryButton";
import { foodRepository, imageRepository } from "domain/repository";
import { Food } from "domain/model/Menu";
import Image from "domain/model/Image";
import { imageService } from "domain/service";
import Loader from "components/atoms/Loader";

type Props = {
  storeId: number;

  category: string;

  name: string;
  price: string;
  description: string;
  image: string;
  s3Images: Image[];

  foods: Food[] | null;

  key: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(1),
    },
    content: {
      margin: theme.spacing(1),
    },
    imageContainer: {
      marginRight: theme.spacing(1),
    },
    menuContainer: {
      width: "100%",
      border: "1px solid #aaa",
      borderRadius: "8px",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      alignItems: "center",
    },
    image: {
      maxWidth: "160px",
    },
    priceInput: {
      margin: theme.spacing(1),
    },
    loading: {
      width: "100vw",
      height: "100vh",
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const FoodDetail: React.FC<Props> = memo(
  ({ storeId, name, price, description, image, s3Images, category, foods, key }) => {
    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState<boolean>(false);
    const [isDeleteOk, setIsDeleteOk] = useState<boolean | null>(null);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setOpen(false);
    };

    const [imagePath, setImagePath] = useState("");
    const [imageId, setImageId] = useState<number>(0);

    const handleDelete = () => {
      setLoading(true);
      foodRepository
        .foodDelete({
          foods,
          storeId,
          name,
          category,
        })
        .then(() => {
          setLoading(false);
          imageRepository.imageDelete(imageId);
          setIsDeleteOk(true);
          setOpen(true);
        })
        .catch((error) => {
          setIsDeleteOk(false);
          setOpen(true);
        })
        .finally(() => {
          setIsDeleteOk(false);
          history.go(0);
        });
    };

    useEffect(() => {
      s3Images.map(d => {
        if (d.s3Path === image) {
          setImageId(d.id);
          setImagePath(d.url);
        }
      });
    }, [image, storeId, s3Images]);

    const DeleteMessage = () => {
      if (isDeleteOk === null) {
        return null;
      }
      if (isDeleteOk) {
        return (
          <>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                見出しを削除しました
              </Alert>
            </Snackbar>
          </>
        );
      }
      return (
        <>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              削除に失敗しました
            </Alert>
          </Snackbar>
        </>
      );
    };

    return (
      <>
        <Grid
          container
          justify="space-around"
          direction="row"
          className={classes.root}
        >
          <Grid item xs={12} sm={2} md={2} className={classes.imageContainer}>
            <img src={imagePath} alt="" className={classes.image} />
          </Grid>
          <Grid item xs={12} sm={9} md={9}>
            <Grid container className={classes.menuContainer} direction="row">
              <Grid item xs={12} sm={8} md={8} className={classes.content}>
                <TextField
                  id="leadlineCreate"
                  label="料理名"
                  defaultValue={name}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.priceInput}>
                <TextField
                  id="leadlineCreate"
                  label="価格"
                  defaultValue={price}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={9} className={classes.content}>
                <TextField
                  id="leadlineCreate"
                  label="内容"
                  defaultValue={description}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2} className={classes.content}>
                <SecondaryButton onClick={() => handleDelete()}>
                  <DeleteIcon />
                  削除
                </SecondaryButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <div className={classes.loading}>
            <Loader />
          </div>
        ) : (
          <></>
        )}
        <DeleteMessage />
      </>
    );
  }
);
