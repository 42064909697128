import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { PrimaryButton } from "../atoms/buttons/PrimaryButton";
import { SecondaryButton } from "../atoms/buttons/SecondaryButton";
import { Box, Snackbar, Typography } from "@material-ui/core";
import { FormikTextField } from "formik-material-fields";
import { Formik, Form, FormikValues } from "formik";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { FoodDetail } from "../molecules/FoodDetail";
import { Food, Items } from "domain/model/Menu";
import Image from "domain/model/Image";
import { foodRepository, imageRepository } from "domain/repository";
import DropImage from "../molecules/DropImage";
import Loader from "components/atoms/Loader";
import { imageService } from "../../domain/service";

type Props = {
  storeId: number;
  items: any | null;
  s3Images: Image[];
  category: string;
  index: number;
  foods: Food[] | null;
  onImageUploaded: Function;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    imageContainer: {
      marginRight: theme.spacing(1),
    },
    menuContainer: {
      width: "100%",
      border: "1px solid #aaa",
      borderRadius: "8px",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      alignItems: "center",
    },
    form: {
      width: "100%",
    },
    name: {
      marginRight: theme.spacing(1),
    },
    price: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    priceInput: {
      marginTop: "-28px",
    },
    foodDetail: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    helperText: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      marginTop: "-28px",
    },
    loading: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      zIndex: 9999,
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const FoodHeading: React.VFC<Props> = ({
  storeId,
  items,
  s3Images,
  category,
  index,
  foods,
  onImageUploaded,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [headlineCount, setHeadlineCount] = useState(0);
  const [desCount, setDesCount] = useState(0);

  const [open, setOpen] = useState<boolean>(false);
  const [isCreateOk, setIsCreateOk] = useState<boolean | null>(null);
  const [isDeleteOk, setIsDeleteOk] = useState<boolean | null>(null);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [files, setFiles] = useState([]);

  const createFood = (values: FormikValues, resetForm: Function) => {
    foodRepository
      .foodCreate({
        foods,
        storeId,
        category: category,
        name: values.name,
        price: values.price,
        description: values.description,
        image: image ? `images/menus/food/stores/${String(storeId)}/${image}` : '',
      })
      .then(() => {
        setLoading(false);
        setIsCreateOk(true);
        setOpen(true);

        resetForm();
        setImage('');
        setImageFile('');
        setFiles([]);
      })
      .catch((error) => {
        setIsCreateOk(false);
        setOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    foodRepository
      .foodCategoryDelete({
        foods,
        storeId,
        category,
      })
      .then(() => {
        setLoading(false);
        setIsDeleteOk(true);
        setOpen(true);
      })
      .catch((error) => {
        setIsDeleteOk(false);
        setOpen(true);
      })
      .finally(() => {
        setLoading(false);
        history.go(0);
      });
  };

  const CreateMessage = () => {
    if (isCreateOk === null) {
      return null;
    }
    if (isCreateOk) {
      return (
        <>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              料理を作成しました
            </Alert>
          </Snackbar>
        </>
      );
    }
    return (
      <>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            作成に失敗しました
          </Alert>
        </Snackbar>
      </>
    );
  };

  const DeleteMessage = () => {
    if (isDeleteOk === null) {
      return null;
    }
    if (isDeleteOk) {
      return (
        <>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              見出しを削除しました
            </Alert>
          </Snackbar>
        </>
      );
    }
    return (
      <>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            削除に失敗しました
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <>
      <Grid
        container
        className={classes.menuContainer}
        justify="space-around"
        direction="row"
      >
        <ListItem button>
          <Grid item xs={12} sm={7} md={7}>
            <Typography>見出し：{category}</Typography>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <SecondaryButton onClick={() => handleDelete()}>
              <DeleteIcon />
              この見出しを全て削除
            </SecondaryButton>
          </Grid>
        </ListItem>
        <Formik
          initialValues={{
            category: category,
            name: "",
            price: "",
            description: "",
          }}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={(values, { resetForm }) => {
            setLoading(true);

            if (files.length) {
              imageRepository
                .imageCreate({ storeId, menuType: "food", files: files[0] })
                .then(() => {
                  onImageUploaded();
                  createFood(values, resetForm);
                })
                .catch((error) => {
                  setIsCreateOk(false);
                  setOpen(true);
                })
                .finally(() => {
                  setLoading(false);
                });
            } else {
              createFood(values, resetForm);
            }
          }}
        >
          {(formik) => (
            <Form
              id={`foodDetailCreate${index}`}
              onSubmit={formik.handleSubmit}
              className={classes.form}
            >
              {" "}
              <Grid
                container
                className={classes.menuContainer}
                justify="space-around"
                direction="row"
              >
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  className={classes.imageContainer}
                >
                  <DropImage imageFile={imageFile} setImage={setImage} setImageFile={setImageFile} setFiles={setFiles} />
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                  <Grid
                    container
                    className={classes.menuContainer}
                    direction="row"
                  >
                    <Grid item xs={12} sm={9} md={9}>
                      <FormikTextField
                        name="name"
                        id="name"
                        variant="outlined"
                        maxLength={50}
                        label="料理名を追加してください"
                        helperText={
                          <Box className={classes.helperText}>
                            <p>50文字以内で入力してください</p>
                            <p>{headlineCount}/50</p>
                          </Box>
                        }
                        value={formik.values.name}
                        onChange={
                          (formik.handleChange,
                          (e: any) => setHeadlineCount(e.target.value.length))
                        }
                        error={formik.errors.name}
                        size="small"
                        fullWidth
                        className={classes.name}
                        characterLimit={50}
                        inputProps={{
                          maxlength: "50",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={2}
                      className={classes.priceInput}
                    >
                      <FormikTextField
                        name="price"
                        id="price"
                        variant="outlined"
                        maxLength={8}
                        label="価格"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={formik.errors.price}
                        size="small"
                        fullWidth
                        className={classes.price}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <FormikTextField
                        name="description"
                        id="description"
                        variant="outlined"
                        maxLength={1000}
                        label="内容"
                        helperText={
                          <Box className={classes.helperText}>
                            <p>1000文字以内で入力してください</p>
                            <p>{desCount}/1000</p>
                          </Box>
                        }
                        value={formik.values.description}
                        onChange={
                          (formik.handleChange,
                          (e: any) => setDesCount(e.target.value.length))
                        }
                        error={formik.errors.description}
                        size="small"
                        fullWidth
                        characterLimit={1000}
                        inputProps={{
                          maxlength: "1000",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} className={classes.button}>
                      <PrimaryButton form={`foodDetailCreate${index}`}>
                        <CreateIcon />
                        追加
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
            </Form>
          )}
        </Formik>
        {items?.items.map((item: Items, key: number) => (
          <div className={classes.foodDetail}>
            <FoodDetail
              foods={foods}
              category={category}
              storeId={storeId}
              name={item.name}
              price={item.price}
              description={item.description}
              image={item.image}
              s3Images={s3Images}
              key={key}
            />
          </div>
        ))}
      </Grid>
      {loading ? (
        <div className={classes.loading}>
          <Loader />
        </div>
      ) : (
        <></>
      )}
      <CreateMessage />
      <DeleteMessage />
    </>
  );
};
