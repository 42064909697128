import api from "utils/api";
import endpoint from "utils/endpoint";

type Drink = {
  category: string;
  name: string;
  price: string;
  description: string;
  image: string;
};

class DrinkRepository {
  imageCreate(arg0: { storeId: number; menuType: string; files: never }) {
    throw new Error("Method not implemented.");
  }
  async drinkCreate({
    storeId,
    drinks,
    category,
    name,
    price,
    description,
    image,
  }: { drinks: any } & { storeId: number } & Drink) {
    drinks.forEach((drinkItem: any) => {
      if (drinkItem.category === category) {
        drinkItem.items.push({ name, price, description, image });
      }
    });
    const body = {
      store_id: storeId,
      menu: {
        type: "drink",
        data: [...drinks],
      },
    };
    const url = endpoint.admin.menu.root();

    return api.post(url, body).catch((error) => {
      if (error.response.data.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }

  async drinkCategoryCreate({
    storeId,
    drinks,
    category,
  }: { drinks?: any } & { category: string } & { storeId: number }) {
    if (drinks !== undefined) {
      const body = {
        store_id: storeId,
        menu: {
          type: "drink",
          data: [
            ...drinks,
            {
              items: [],
              category: category,
            },
          ],
        },
      };
      const url = endpoint.admin.menu.root();

      return api.post(url, body).catch((error) => {
        if (error.response.data.error === undefined) {
          return Promise.reject("error");
        }

        return Promise.reject(error.response.data.error);
      });
    } else {
      const body = {
        store_id: storeId,
        menu: {
          type: "drink",
          data: [
            {
              items: [],
              category: category,
            },
          ],
        },
      };
      const url = endpoint.admin.menu.root();

      return api.post(url, body).catch((error) => {
        if (error.response.data.error === undefined) {
          return Promise.reject("error");
        }

        return Promise.reject(error.response.data.error);
      });
    }
  }

  async drinkDelete({
    drinks,
    storeId,
    name,
    category,
  }: { drinks: any } & { storeId: number } & { name: string } & {
    category: string;
  }) {
    const removedrink = drinks.map((drinkItem: any) => {
      if (drinkItem.category === category) {
        return {
          category: drinkItem.category,
          items: drinkItem.items.filter((item: any) => {
            return item.name !== name;
          }),
        };
      }
      return { category: drinkItem.category, items: drinkItem.items };
    });

    const body = {
      store_id: storeId,
      menu: {
        type: "drink",
        data: removedrink,
      },
    };
    const url = endpoint.admin.menu.root();

    return api.post(url, body).catch((error) => {
      if (error.response.data.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }

  async drinkCategoryDelete({
    drinks,
    storeId,
    category,
  }: { drinks: any } & { storeId: number } & {
    category: string;
  }) {
    const removedrink = drinks.filter((drinkItem: any) => {
      return drinkItem.category !== category;
    });

    const body = {
      store_id: storeId,
      menu: {
        type: "drink",
        data: removedrink,
      },
    };
    const url = endpoint.admin.menu.root();

    return api.post(url, body).catch((error) => {
      if (error.response.data.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }
}

export default new DrinkRepository();
