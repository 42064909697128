import React, { useCallback, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import { Box, Paper, Typography } from "@material-ui/core";

type Props = {
  imageFile: string;
  setImage: any;
  setImageFile: any;
  setFiles: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "200px",
      position: "relative",
    },
    dropImage: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateY(-50%) translateX(-50%)",
    },
    image: {
      maxWidth: "160px",
    },
  })
);

const DropImage: React.FC<Props> = (props) => {
  const classes = useStyles();
  // const [img, setImg] = useState("");
  const [imgFile, setImgFile] = useState("");
  // const [files, setFiles] = useState([]);

  // props.setImage(img);
  // props.setFiles(files);

  const accept = "image/*"; //"image/jpeg, image/png";
  const onDrop = useCallback((acceptedFiles) => {
    props.setFiles(acceptedFiles);
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL;
    if (acceptedFiles.length !== 0) {
      props.setImageFile(createObjectURL(acceptedFiles[0]));
      props.setImage(acceptedFiles[0].name);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
  });

  return (
    <>
      <Box className={classes.root}>
        <Paper
          variant="outlined"
          square
          {...getRootProps()}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: 10,
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>
              こちらに画像をドラッグ&ドロップしてください ...
            </Typography>
          ) : (
            <Typography>
              こちらに画像をドラッグ&ドロップするか、もしくはクリックしてファイルを選択してください
            </Typography>
          )}
          <div className={classes.dropImage}>
            <img src={props.imageFile} alt="" className={classes.image} />
          </div>
        </Paper>
      </Box>
    </>
  );
};
export default DropImage;
